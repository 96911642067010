function show_video( $video ){
	if ( !$video ){
		return false;
	}

	var windowHeight = jQuery(window).outerHeight(),
		windowWidth = jQuery(window).outerWidth();

	jQuery('#main-modal').on('show.bs.modal', function (e) {
		var iFrame,
			modalHeight;

		jQuery(this).addClass('video-modal');
		// set modal height
		modalHeight = parseInt( windowHeight - (windowHeight / 4) );
    modalWidth = parseInt( windowWidth - (windowWidth / 2.5) );
    if ( windowWidth < 767 ) {
      modalWidth = parseInt( windowWidth - 15 );
    }

		jQuery(this).find('.modal-dialog').css({
			width: modalWidth,
			height: modalHeight
		});

		iFrame = '<iframe id="ytplayer" type="text/html" width="' + modalWidth + '" height="' + modalHeight + '"' +
			'src="http://www.youtube.com/embed/'+ $video +'?autoplay=1&origin=http://example.com"' +
			'frameborder="0"/>';

		jQuery(this).find('.modal-body')
			.html(iFrame)
			.css('height', jQuery('#ytplayer').outerHeight());

	});

	jQuery('#main-modal').on('shown.bs.modal', function(e) {

	});

	jQuery('#main-modal').on('hidden.bs.modal', function (e) {
		jQuery(this).removeClass('video-modal');

		jQuery('#ytplayer').remove();
	});

	jQuery('#main-modal').modal();

}

function imgSelect($source, $target, $self){
  if (typeof($source) === 'undefined' || typeof($target) === 'undefined' || typeof($self) === 'undefined'){
    return false;
  }

  jQuery('.image-item').removeClass('active');
  jQuery('.' + $self).parent().addClass('active');

  jQuery('#' + $target)
    .attr('href', $source)
    .find('img')
    .attr({
      'src': $source,
      'srcset': ''
    });
}

jQuery('#load-more-post').click(function(e) {
  var el = jQuery(this),
    pageNumber = el.attr('data-page'),
    category = el.attr('data-category'),
    isTag = el.attr('data-tag'),
    scrollPos = jQuery(window).scrollTop();

  el.text('Loading...');

  $.ajax({
    url: RedObj.ajaxurl,
    type: 'POST',
    dataType: 'html',
    data: {
      action: 'redajax-get-posts',
      page: pageNumber,
      nonce: RedObj.red_nonce,
      category: category,
      istag: isTag
    }
  })
  .done(function(response) {
    if (response == 'false') {
      el.text('No more posts');
    } else {
      jQuery('#blog-post-list').append(response);
      el
        .attr('data-page', parseInt(pageNumber) + 1 )
        .text('Load more');
    }

  })
  .fail(function() {
    el.text('No more posts');
  })
  .complete(function(){
    if ( jQuery('#blog-post-list .news__post').length > 0 ) {
      jQuery('#blog-post-list .news__post').matchHeight();
    }

    // set scrolltop position
    jQuery(window).scrollTop(scrollPos);
  });
});
