/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  var $w = $(window),
    $body = $('body'),
    $header = $('header.banner'),
    $header_offset = 76,
    Opt = {
      Select : {
        dropDownClass: 'red-select',
        initValue: function () {
          var el = $(this);
          if ( el.context.name === 'msg-category' ) {
            return 'Category' + '<i class="arrow"></i>';
          } else {
            return el.text() + '<i class="arrow"></i>';
          }
        },
        valueTemplate: function () {
          return $(this).text() + '<i class="arrow"></i>';
        }
      },
      Checkbox : {
        checked: RedObj.radiochecked, // Checked image
        unchecked: RedObj.radiounchecked,   // Unchecked image
        trigger: "parent", // Can be self, parent
        tristate : false // Use tri state? need to be ul > li > checkbox // ul > li > ul > li > checkbox
      },
      Radio : {
        base : "images",
        checked: RedObj.radiochecked, // Checked image
        unchecked: RedObj.radiounchecked   // Unchecked image
      },
      MainMenu : {
        hover: function () {
          $header.on('mouseenter', function(){
            $body.addClass('header-hover');
          });
          
          $header.on('mouseleave', function(){
            $body.removeClass('header-hover');
          });
          
        }
      },
      Loadmore: {
        init: function() {
          //.main-section
          $body.on('click', '#append-next-page', function(e) {
            e.preventDefault();
            
            var el = $(this),
              target= el.attr('href'),
              result = '',
              url = '';
              
            if (!target) {
              el.text('No More Posts');
              return false;
            }
            
            el.text('loading...');
            
            $.ajax({
              url: target,
              type: 'GET',
              dataType: 'html'
            })
            .done(function(e) {
              result = $(e).find('.post-card-wrapper > .col-md-4');
              url = $(e).find('#append-next-page').attr('href');
              
              $('.post-card-wrapper').append(result);
              $('.post-card .white-area').matchHeight();
              el.attr('href', url);
              el.text('Load More');
            })
            .fail(function() {
              console.log("error");
              el.text('No more posts');
            })
            .always(function() {
              console.log("complete");
            });
            
          });
          
          $body.on('click', '#ajax-get-posts', function(e) {
            e.preventDefault();
            
            var el = $(this),
              $offset = el.attr('data-offset');
              
            el.text('loading...');
            
            $.ajax({
              url: RedObj.ajaxurl,
              type: 'POST',
              dataType: 'json',
              data: {
                action: 'thecut-get-posts',
                nonce: RedObj.red_nonce,
                offset: $offset
              }
            })
            .done(function(e) {
              console.log('success');
              $('.post-card-wrapper').last().append(e.html);
              $('.post-card .white-area').matchHeight();
              $('#ajax-get-posts').attr('data-offset', e.nextOffset);
              el.text('Load More');
            })
            .fail(function() {
              console.log("error");
              el.text('No more posts');
            })
            .always(function() {
              console.log("complete");
            });
            
          });
        }
      },
      checkOffset: {
        init: function() {
          var $offset = 0;
          
          if ($w.outerWidth() > 991) {
            // desktop
            $offset = $('header.banner').offset().top + $('header.banner').outerHeight();
            
            if ($('body').hasClass('page-scroll')) {
              $offset = $('header.banner').outerHeight();
            }
          } else {
            // mobile
            if ($('header.mobile').hasClass('sticky')) {
              $offset = $('header.mobile').position().top + $('header.mobile').outerHeight();
            } else {
              $offset = ( $('.site-wrap').offset().top - $w.scrollTop() ) + $('header.mobile').outerHeight();
            } 
            
          }
          
          return $offset;
        }
      }
    },
    ScrollState = 0,
    Slideshow = {
      imageInit: function(){        
        $('.thecut-slideshow.slick-initialized').slick('unslick');
        
        $('.thecut-slideshow img').attr('style', '');

        // add class based on orientation
        $('.thecut-slideshow img').each(function(index, el) {
          if ($(el).width() < $(el).height()) {
            $(el).addClass('img-portrait');
          } else if ($(el).width() == $(el).height()) {
            $(el).addClass('img-square');
          } else {
            $(el).addClass('img-landscape');
          }
        });
        
        var $highest_el,
          $highest_val = 0;
        // get highest landscape el
        $('.thecut-slideshow img.img-landscape').each(function(index, el) {
          if ($(el).height() > $highest_val) {
            $highest_val = $(el).height();
            $highest_el = $(el);
          }
        });
        // set img height based on the highest landscape el
        if ($highest_val > 0) {
          $('.thecut-slideshow img').height($highest_val);
        }
        
        $('.thecut-slideshow').slick({
          accessibility: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable: false,
          prevArrow: '<span class="hero-prev-arrow"><i class="glyphicon glyphicon-chevron-left"></i></span>',
          nextArrow: '<span class="hero-next-arrow"><i class="glyphicon glyphicon-chevron-right"></i></span>',
          dots: false,
          adaptiveHeight: true,
        });
      }
    };

  // desvg
  window.addEventListener('load', function(){
    // 1. selector for the <img /> tags to replace
    // 2. whether to strip inline style tags from SVG paths
    deSVG('.svg-img', true);
  });

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var wW = $w.outerWidth();
        
        //init menu scripts
        Opt.MainMenu.hover();

        // check if page position is already scrolled
        ScrollState = $(window).scrollTop();
        // add class on scroll
        if ( wW > 991 ) {
          if ( ScrollState > $('.site-wrap').offset().top ) {
            $('body').addClass('page-scroll');
          }
        }

        // check popup cookie
        if ($('#woo-popup').length > 0) {
          var $cookie = $('#woo-popup').attr('data-cookie'),
            cookieCheck = Cookies.get($cookie),
            $delay = $('#woo-popup').attr('data-delay'),
            $mainBody = $('body');

          // if ($mainBody.hasClass('logged-in')) return;
          if (!$mainBody.hasClass('logged-in')){

            if (!$delay || $delay === '') {
              $delay = 15;
            }

            $delay = parseInt($delay) * 1000;

            if (typeof cookieCheck == 'undefined' || cookieCheck !== 'true') {
              $(window).load(function () {
                setTimeout(function () {
                  $('body').addClass('newsletter-popup');
                  Cookies.set($cookie, 'true', { expires: 1 });
                }, $delay);
              });

              $('body').on('click', '.popup-close', function (event) {
                event.preventDefault();
                $('body').removeClass('newsletter-popup');
              });
              $('body').on('click', '.popup-hide a', function (event) {
                event.preventDefault();
                $('body').removeClass('newsletter-popup');
              });
            }
          }
        }
        // $('.popup-facebook .wp-social-login-provider-facebook').html('<span></span>Connect with Facebook');
        // $('.popup-facebook .wp-social-login-provider-twitter').html('<span></span>Connect with Twitter');
        
        Opt.Loadmore.init();
        
        // leaderboards
        if ($('.iklan-leaderboard').length > 0) {
          // if leaderboard ad exist
          // var $iklan = $('.iklan-leaderboard').clone(),
          var $leaderboardHeight = 0;
          
          // $iklan.removeClass('hide');
          $body.addClass('hasLeaderboard');
        
          // $('.iklan-leaderboard').remove();
          // $('#leaderboard-base').html($iklan);
          
          $leaderboardHeight = $('#leaderboard-base .modul-iklan-container').outerHeight();
          
          $w.load(function(){
            $('.nav-primary .nav .sub-menu-wrap').css('top', $leaderboardHeight + 70);
            // $('header.mobile').css('top', $leaderboardHeight);
            // $('#leaderboard-base .iklan-leaderboard').addClass('show');
          });
        }
        
        // sticky sidebar
        if ($('.sidebar-area').length > 0) {
          var $sidebar = $('.sidebar-area'),
            $sidebartop = 0,
            $sidebarbottom = 0;
          
          $w.bind('resize', function(event) {
            if ( wW > 991 ) {
              $sidebar.css('max-width', $sidebar.parent().width());
            }
          });
          
          $w.load(function(){
            if ( wW > 991 ) {
              $sidebar.css('max-width', $sidebar.parent().width());
            }
            $sidebartop = $sidebar.offset().top;
            $sidebarbottom = $body.height() - $('.editor-pick__container').offset().top;// $sidebar.offset();
            
            $sidebar.affix({
              offset: {
                top: $sidebartop,
                bottom: $sidebarbottom
              }
            });
            
          });
          
        }

        $(window).bind('resize', function(event) {
          if ( wW < 991 ) {
            $('body').removeClass('page-scroll');
          }
        });

        $(window).scroll(function(event) {
          ScrollState = window.pageYOffset || document.documentElement.scrollTop;
          $header_offset = $('.site-wrap').offset().top;
          
          if ( wW > 991 ) {
            $body.removeClass('open-search');
            if ( ScrollState > $header_offset ) {
              $('body').addClass('page-scroll');
            } else {
              $('body').removeClass('page-scroll');
            }
          } else {
            var $offset = Opt.checkOffset.init();
            $('.site-wrap .search-container').css('top', $offset);
          }
        });

        // select
        // $("select.wpcf7-select").transformSelect(Opt.Select);
        // $('select.country_select,select.state_select ').transformSelect(Opt.Select);

        // $(".wpcf7-select").transformSelect(Opt.Select);
        // checkbox
        $("input:checkbox").transformCheckbox(Opt.Checkbox);
        $("input:radio").transformRadio(Opt.Radio);
        
        // menu mobile
        $('.mobile-menu-wrapper').on('click', '.menu-item-has-children a', function(e){
          e.preventDefault();
          $(this).parent().toggleClass('open');
        });
        // end menu mobile

        if ($('.read-more-content').length > 0) {
          $('.read-more-content .text-content-second').hide('fast');

          $('.read-more-text').on('click', function(event) {
            event.preventDefault();
            var el = $(this),
              expand = el.attr('data-expand');
            if (expand === 'false') {
              el.parent().find('.text-content-second').show('fast', function() {
                el
                  .attr('data-expand', 'true')
                  .text('Read less');
              });
            } else {
              el.parent().find('.text-content-second').hide('fast', function() {
                el
                  .attr('data-expand', 'false')
                  .text('Read more');
              });
            }
          });
        }

        // blog slideshow
        // if ($('.hero-slide__content').length > 0) {
        //   if ($('.hero-slide__content').height() < $w.height()) {
        //     $('.hero-slide').height( $w.height() - 100 );
        // 
        //     $('.hero-slide__content').parent().addClass('hero-slide-center');
        //   }
        // }


        // blog category mobile on change
        // if ( $('.blog-category__container').length > 0 ) {
        //   $('.blog-category__container select').on('change', function(event) {
        //     event.preventDefault();
        //     window.location.href = $(this).val();
        //   });
        // }

        // blog detail
        if ($('.header-hero').length > 0) {
          var $header = $('header .header-hero');
          if ($w.outerWidth() > 991) {
            $header
              .css({
                'min-height': $header.height(),
                'height': $w.outerHeight()
              })
              .parent().addClass('full-height');
          }

          $w.bind('resize', function(event) {
            if ($w.outerWidth() > 991) {
              $header
                .css({
                  'min-height': $header.height(),
                  'height': $w.outerHeight()
                })
                .parent().addClass('full-height');
            }
          });
        }
        if ($('.post-data').length > 0) {
          var $img, $fullimg, $alt, $target, $html = '';
          $('.post-data img').addClass('img-responsive');
          // add link to image
          $('.post-data img').each(function(index, el) {
            if (!$(el).hasClass('no-link')) {
              if ( typeof $(el).parent().attr('href') == 'undefined' ) {
                
                if (typeof $(el).attr('srcset') !== 'undefined') {
                  $fullimg = $(el).attr('srcset');
                  
                  $fullimg = $fullimg.split(',');
                  $fullimg = $fullimg[$fullimg.length - 1];
                  $fullimg = $fullimg.split(' ');
                  
                  $img = $fullimg[1];
                } else {
                  $img = $(el).attr('src');
                }
                
                $alt = $(el).attr('alt');

                $(el).wrap('<a href="' + $img + '" title="' + $alt + '"></a>');
              }
            }
            
            $target = $(el).parent().attr('target');
            if( $target  !== '_blank' || typeof $target == 'undefined'){
              $(el).parent().fluidbox({
                immediateOpen: true
              });
            }
          });

          // $('.post-data a img').parent().fluidbox({
          //   immediateOpen: true
          // });
          // $w.bind('scroll', function(event) {
          //   $('.post-data a').fluidbox('close');
          // });
          
          if ($('.thecut-slideshow').length > 0) {
            Slideshow.imageInit();
            
            $(window).on('resize', function(){
              Slideshow.imageInit();
            });
            
          }
          
          
          
        }

        // menu mobile
        $('.burger-menu').on('click', function(event) {
          event.preventDefault();
          if ( $('body').hasClass('menu-active') ) {
            $('body').removeClass('menu-active');
          } else {
            $('body').addClass('menu-active');
            $('header.mobile').addClass('sticky');
          }
        });

        // featured posts
        // if ( $('.featured-post__container').length > 0 ) {
        //   $('.featured-post__container > .container > .row > .col-sm-6').matchHeight();
        // }
        if ( $('#blog-post-list .news__post').length > 0 ) {
          $('#blog-post-list .news__post').matchHeight();
        }

        // newsletter
        if ( $('.newsletter__shortcode').length > 0 ) {
          $('.newsletter__shortcode input[type="email"]').on('focus', function(event) {
            $('.newsletter__shortcode').addClass('focus');
          });

          $('.newsletter__shortcode input[type="email"]').on('blur', function(event) {
            $('.newsletter__shortcode').removeClass('focus');
          });
        }
        
        $('.right-menu .search').on('click', function(e){
          e.preventDefault();
          var $offset = Opt.checkOffset.init();
          
          $('.site-wrap .search-container').css('top', $offset);
          
          setTimeout(function () {
            $body.toggleClass('open-search');
            setTimeout(function(){
              $('.search-form .search-field').focus();
            }, 300);
          }, 200);
        });
        
        $('.mobile-header .search').on('click', function(e){
          e.preventDefault();
          var $offset = Opt.checkOffset.init();
          
          $('.site-wrap .search-container').css('top', $offset);

          setTimeout(function () {
            $body.toggleClass('open-search');
            setTimeout(function(){
              $('.search-form .search-field').focus();
            }, 300);
          }, 200);          
        });
        
        $('.close-search').on('click', function(){
          $body.removeClass('open-search');
        });
        $('.search-field').on('keypress', function(e){
          console.log('key pressed');
          if(e.which == 13) {
            if ($(this).val().length < 3) {
              $(this).val('').attr('placeholder', 'Please search using 3 characters or more');
              return false;
            }
            
          }
        });
        // match height
        $('.post-card,.news__post').matchHeight();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // add sticky class
        if ($w.outerWidth() > 991) {
          $('body').css('height', 'auto');
          if ( $('body').outerHeight() < $w.outerHeight() ) {
            // add sticky class
            $('footer.content-info').addClass('sticky-footer');
            // set body height
            $('body').css('height', $w.outerHeight() - ( $('header.banner').outerHeight() + $('footer.content-info').outerHeight() ) );
          }
        } else {
          $('footer.content-info').removeClass('sticky-footer');
          $('body').css('height', 'initial');
        }
        $w.bind('resize', function(event) {
          if ($w.outerWidth() > 991) {
            $('body').css('height', 'auto');
            if ( $('body').outerHeight() < $w.outerHeight() ) {
              // add sticky class
              $('footer.content-info').addClass('sticky-footer');
              // set body height
              $('body').css('height', $w.outerHeight() - ( $('header.banner').outerHeight() + $('footer.content-info').outerHeight() ) );
            }
          } else {
            $('footer.content-info').removeClass('sticky-footer');
            $('body').css('height', 'initial');
          }
        });

        wW = $w.outerWidth();
        
        $w.load(function(){
          // add class on scroll
          if ( wW < 991 ) {
            var lastScrollTop = 0,
              mHeader = $('header.mobile'),
              mHeaderTop = mHeader.offset().top + mHeader.outerHeight();
              
            $(window).scroll(function(event){
              var st = $(this).scrollTop();
              
              // if (st < lastScrollTop){
                if (st < mHeaderTop) {
                  mHeader.removeClass('sticky');
                } else {
                  mHeader.addClass('sticky');
                }
              // } else {
              //   mHeader
              //     .removeClass('up')
              //     .addClass('down');
              // }
              // lastScrollTop = st;
            });
          
          }

        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        /*
        var $winHeight = $(window).outerHeight();

        // home animation init
        // new WOW().init();
        
        if ( $('.hero__container').length > 0 ) {
          var $heroHeight = $('.hero__container').innerHeight();

          if ( $w.outerWidth() > 991 ) {
            // if window higher than hero container
            if ($winHeight > $heroHeight) {
              var $margin = ($winHeight - $heroHeight) / 4;

              $('.hero__content').css({
                marginTop: $margin + $margin,
                marginBottom: $margin
              });
            }
          } else {
            $('.hero__content').css({
              marginTop: 0,
              marginBottom: 0
            });
          }

          $w.bind('resize', function(event) {
            $winHeight = $(window).outerHeight();
            $heroHeight = $('.hero__container').innerHeight();

            if ( $w.outerWidth() > 991 ) {
              // if window higher than hero container
              if ($winHeight > $heroHeight) {
                var $margin = ($winHeight - $heroHeight) / 4;

                $('.hero__content').css({
                  marginTop: $margin + $margin,
                  marginBottom: $margin
                });
              }
            } else {
              $('.hero__content').css({
                marginTop: 0,
                marginBottom: 0
              });
            }
          });

          $('body').on('click', '.hero__arrow', function(event) {
            event.preventDefault();
            var y = $(window).scrollTop(); //your current y position on the page
            $("html, body").animate({ scrollTop: y + $winHeight });
          });
        }
        */

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'contact_us': {
      init: function() {

      }
    },
    'login':{
      init: function() {
        
        $('.fb-login .wp-social-login-provider-facebook').html('<span></span>Login with Facebook');
        
        $('.fb-login .wp-social-login-provider-google').html('<span></span>Login with Google');
        
        $('.fb-login .wp-social-login-provider-twitter').html('<span></span>Login with Twitter');
        
      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
